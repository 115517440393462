<template>
  <wp-items-view
    content-type="pages"
    content-type-singular="page"
    content-name="Pages"
    content-name-singular="Page"
  />
</template>

<script>
import WpItemsView from '@/views/wp-item/wp-items-view/WpItemsView.vue'

export default {
  components: {
    WpItemsView,
  },
}
</script>

<style scoped>

</style>
